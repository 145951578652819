import type { FunctionComponent } from 'react'
import { assertNever } from '@which/shared'

import type { ArticleListingFilteredPage, Callout } from '../../generated/frontend'
import { ErrorComponent, PageError } from '../../shared/components/Error'
import { Loader } from '../../shared/components/Loader'
import { Redirect } from '../../shared/components/Redirect'
import { PageTemplate } from '../../shared/templates/PageTemplate'
import type {
  ArticleListingAuthorPageData,
  ArticleListingCrPageData,
  ArticleListingTagPageData,
} from './article-listing-types'
import { useArticleListingMetaTags } from './hooks/useArticleListingMetaTags'
import { useArticleListingPageComponents } from './hooks/useArticleListingPageComponents'
import { useArticleListingPageQuery } from './hooks/useArticleListingPageQuery'

const ArticleListingPage: FunctionComponent = () => {
  const { loading, error, data } = useArticleListingPageQuery()
  const { getMetaTags } = useArticleListingMetaTags()
  const { getPageComponents } = useArticleListingPageComponents()

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data || !data.articleListingPage) {
    return <PageError pageName="Article Listing Page" />
  }

  const { articleListingPage } = data

  /* Redirect to product hub if there is one */
  const categoryIsProductHub =
    articleListingPage.meta?.taxonomyHierarchy?.category?.additional_items
      ?.custom_taxonomy_configuration?.meta_data?.is_product_hub

  if (categoryIsProductHub) {
    const subVerticalSlug = articleListingPage.meta.taxonomyHierarchy.subVertical?.slug
    const categorySlug = articleListingPage.meta.taxonomyHierarchy.category?.slug
    return <Redirect to={`/l/${subVerticalSlug}/${categorySlug}`} />
  }

  switch (articleListingPage.__typename) {
    case 'ArticleListingFilteredPage':
      const { author, tags } = getNewsPageMetaValues(
        articleListingPage as ArticleListingFilteredPage
      )

      return (
        <PageTemplate
          metaTags={getMetaTags({
            meta: articleListingPage.meta,
            author,
            tags,
          })}
          components={getPageComponents(articleListingPage)}
          showResubBanner={articleListingPage.showResubBanner}
        />
      )
    case 'ArticleListingPage':
      return (
        <PageTemplate
          metaTags={getMetaTags({
            meta: articleListingPage.meta,
            callout: (articleListingPage as ArticleListingCrPageData).callout as Callout,
          })}
          components={getPageComponents(articleListingPage)}
          showResubBanner={articleListingPage.showResubBanner}
        />
      )
    default:
      assertNever(articleListingPage)
  }
}

export default ArticleListingPage

///////// IMPLEMENTATION /////////

const getNewsPageMetaValues = (articleListingPage: ArticleListingFilteredPage) => {
  const author =
    (articleListingPage as ArticleListingAuthorPageData).newsAuthorHeader?.author?.name ?? ''
  const tags = (articleListingPage as ArticleListingTagPageData)?.newsTagHeader?.name

  return {
    tags,
    author,
  }
}
